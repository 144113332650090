import { Route, Routes, useLocation } from 'react-router-dom';
import Landing from './Landing';
import OutputView from './BlendResult/OutputView';
import { useEffect, useState, useCallback } from 'react';
import PageNotFoundView from './PageNotFoundView';
import trackPathForAnalytics from './GAAnalytics';

function App() {

  const [baseURL, setBaseURL] = useState(null)
  const [env, setEnv] = useState(null)

  useEffect(() => {
    const url = window.location.href
    if (url.includes("/dev")) {
      setBaseURL("https://us-central1-foot-print-app-dev.cloudfunctions.net/footprint_api")
      setEnv("dev")
    } else {
      setBaseURL("https://us-central1-foot-print-app.cloudfunctions.net/footprint_api")
      setEnv("prod")
    }
  }, []);

  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <Routes>
      <Route path="/" element={<Landing baseURL={baseURL} env={env} />} />
      <Route path="/result" element={<OutputView baseURL={baseURL} env={env} />}></Route>
      <Route path="/result/" element={<OutputView baseURL={baseURL} env={env} />}></Route>
      <Route path="/result/:blendId" element={<OutputView baseURL={baseURL} env={env} />}></Route>

      {/* <Route path="/dev" element={<Landing baseURL={baseURL} env={env} />} />
      <Route path="/dev/result" element={<OutputView baseURL={baseURL} env={env} />}></Route>
      <Route path="/dev/result/" element={<OutputView baseURL={baseURL} env={env} />}></Route>
      <Route path="/dev/result/:blendId" element={<OutputView baseURL={baseURL} env={env} />}></Route> */}

      <Route path="*" element={<PageNotFoundView baseURL={baseURL} env={env} />}></Route>
    </Routes>
  );
}

export default App;