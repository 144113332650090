import { useEffect, useState, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./OutputView.css"
import ResultImageView from "./ResultImageView";
import ResultFooterView from "./ResultFooterView";
import LogoHeaderView from "../HeaderView/LogoHeaderView";
import * as htmlToImage from "html-to-image";
import Mixpanel from "../MixpanelAnalytics";
import refreshArrows from "../Assets/arrow-refresh.png"

const createFileName = (extension = "", ...names) => {
    if (!extension) {
        return "";
    }

    return `${names.join("")}.${extension}`;
};

const OutputView = ({ baseURL, env }) => {
    const { blendId } = useParams(); // Read blendId from URL param
    const navigate = useNavigate();

    const [downloadWithOriginals, setDownloadWithOriginals] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [blendData, setBlendData] = useState(null)

    const downloadRef = createRef(null);
    const [isDownloadingResult, setIsDownloadingResult] = useState(null)

    const takeScreenShot = async (node) => {
        await htmlToImage.toJpeg(node, { backgroundColor: "white" });
        await htmlToImage.toJpeg(node, { backgroundColor: "white" });
        // await htmlToImage.toJpeg(node, { backgroundColor: "white" });

        const dataURI = await htmlToImage.toJpeg(node, { backgroundColor: "white" });
        return dataURI;
    };

    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };


    useEffect(() => {
        if (!blendId && env) {
            env === "dev" ? navigate("/dev") : navigate("/")
        } else {
            if (baseURL) {
                fetchBlend(blendId)
            }
        }
    }, [blendId, baseURL, env])

    useEffect(() => {
        if (isDownloadingResult) {
            const screenshotAndDownload = async () => {
                try {
                    const imageData = await takeScreenShot(downloadRef.current)

                    if (navigator.share) {
                        try {
                            const file = getFileFromBase64(imageData, 'img.jpg');

                            await navigator.share({
                                title: 'Mixmatch Result!',
                                text: '',
                                files: [file],
                            });

                            console.log('Successfully shared');
                        } catch (error) {
                            console.error('Error sharing', error);
                        }
                    } else {
                        download(imageData)
                    }
                } catch {
                    console.log("Wow, error")
                }
                setIsDownloadingResult(false)
            }
            screenshotAndDownload()
        }
    }, [isDownloadingResult])

    function getMimeTypeFromBase64(base64Data) {
        if (base64Data.includes("base64")) {
            const mimeType = base64Data.substring("data:".length, base64Data.indexOf(";base64"))
            return mimeType
        } else {
            const firstChar = base64Data.charAt(0)
            const mimeType = firstChar === "/" ? "image/jpeg" : firstChar === "i" ? "image/png" : firstChar === "R" ? "image/gif" : "image/jpeg"
            return mimeType
        }
    }

    function getFileFromBase64(string64, fileName) {
        const mimeType = getMimeTypeFromBase64(string64)
        const base64EncodedImageString = string64.replace(/^data:image\/\w+;base64,/, '')
        const imageContent = atob(base64EncodedImageString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);

        for (let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }
        const blob = new Blob([buffer], { mimeType });
        return new File([blob], fileName, { lastModified: new Date().getTime(), mimeType });
    }

    const fetchBlend = (blendId) => {
        setIsLoading(true)
        fetch(`${baseURL}/web/blend?blend_id=${blendId}`)
            .then(response => response.json())
            .then(data => {
                if (data.blends && data.blends[0]) {
                    setBlendData(data.blends[0])
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', JSON.stringify(error));
                setIsLoading(false);
            });
    }

    const downloadClicked = () => {
        if (!isDownloadingResult) {
            Mixpanel.track('click_download', {
                screen: "result_page",
                download_with_originals: downloadWithOriginals
            });

            setIsDownloadingResult(true)
        }
    }

    const refreshClicked = () => {
        const url = `${baseURL}/web/blend`;

        const body = {
            'item_id': blendData.item_data.item_id,
            'input_image_urls': blendData.image_urls
        }

        Mixpanel.track('click_create_another', {
            screen: "result_page",
            item_id: blendData.item_data.item_id,
            item_title: blendData.item_data.title,
            num_of_images: blendData.image_urls.length
        });

        setIsLoading(true)

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.blend)
                if (data.blend) {
                    env === "dev" ? navigate(`/dev/result/${data.blend.blend_id}`) : navigate(`/result/${data.blend.blend_id}`)
                    setBlendData(data.blend)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div className="results-page">
            <div className="result-header">
                <LogoHeaderView screen={"result_page"} />
            </div>

            <div className="results-container">
                {/* <div className="breadcrumb">
                    <a href="/">Home </a> &gt; <span> Results</span>
                </div> */}
                <div className="images-container" ref={downloadRef}>
                    {downloadWithOriginals &&
                        <div className="input-images">
                            {blendData.image_urls.map((image_url, index) => (
                                <img key={index} onContextMenu={handleContextMenu} src={image_url} alt="Input" className="input-image" />
                            ))}
                        </div>
                    }
                    <ResultImageView isLoading={isLoading} blendData={blendData} isDownloadingResult={isDownloadingResult} />
                </div>
                {!isLoading && !isDownloadingResult && <div className="create-button" onClick={refreshClicked}>
                    <img src={refreshArrows} alt=""></img>
                    <p>Create Another Image</p>
                </div>}
            </div>

            <div className="footer-container">
                <ResultFooterView downloadClicked={downloadClicked} downloadWithOriginals={downloadWithOriginals} setDownloadWithOriginals={setDownloadWithOriginals} />
            </div>
        </div>
    )
}


export default OutputView