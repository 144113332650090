
import React, { useState } from 'react';
import "./CelebritySearchBar.css"
import { ReactComponent as SearchIcon } from '../Assets/magnifying-glass.svg'

const CelebritySearchBar = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleInputChange = (event) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
        if (onSearch) {
            onSearch(newQuery);
        }
    };

    return (
        <div className="search-celebrity">
            <SearchIcon/>
            <input
                type="text"
                placeholder="Search a celebrity"
                value={query}
                onChange={handleInputChange}
                className="search-input"
            />
        </div>
    )
}

export default CelebritySearchBar