import React from "react";
import "./LogoHeaderView.css";
import logo512 from "../Assets/logo512.png";
import Mixpanel from "../MixpanelAnalytics";

const LogoHeaderView = ({ screen }) => {
  return (
    <div className="logo-header">
      <div className="about-button"
        onClick={() => {
          Mixpanel.track('click_about', {
            screen: screen ?? "N/A"
          });
          window.location.href = "https://gomixmatch.com/"
        }}>
        About
      </div>
      <div className="inner-logo-title">
        <a href="/">
          <h1>Mixmatch</h1>
        </a>
        <a href="/">
          <img
            src={logo512}
            className="logo-header-image"
            alt="Landing header logo"
          />
        </a>
      </div>
      <div
        className="contact-button"
        onClick={() => {
          Mixpanel.track('click_contact', {
            screen: screen ?? "N/A"
          });
          window.location.href = "mailto:hi@santasailab.com"
        }}>
        Contact
      </div>
    </div>
  );
};

export default LogoHeaderView;
