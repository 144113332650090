import './HeaderView.css';
import LogoHeaderView from "./LogoHeaderView"
import CreateBlendImageView from '../CreateBlendImageView/CreateBlendImageView';

const HeaderView = ({ baseURL, images, setImages, addAnotherImage, setAddAnotherImage }) => {
  return (
    <div className="header">
      <LogoHeaderView screen={"main_page"} />
      <CreateBlendImageView baseURL={baseURL} images={images} setImages={setImages} addAnotherImage={addAnotherImage} setAddAnotherImage={setAddAnotherImage} />
    </div >
  );
};

export default HeaderView;