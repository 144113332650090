import React from 'react';
import "./SelectedImagesView.css"
import SelectedImageItemView from "./SelectedImageItemView"
import { ReactComponent as PlusIcon } from '../Assets/plus.svg'

const SelectedImagesView = ({ images, onDelete, addAnotherImage }) => {

    return (
        <div className="selected-images-container">
            <div className="selected-images-items-container">
                {images.map((image, index) => (
                    <SelectedImageItemView key={index} image={image} onDelete={() => onDelete(index)} />
                ))}
            </div>
            {(images.length < 3) ? 
            (
                <button className="selected-images-add-another-image-button" onClick={addAnotherImage}>
                    <PlusIcon/>
                    <p>Add another image</p>
                </button>
            ) : (null)
            }
        </div>
    )
}

export default SelectedImagesView