import mixpanel from 'mixpanel-browser';

mixpanel.init('d6c120a507772651f27dd550e8b6469a', { debug: true, track_pageview: true, persistence: 'localStorage' });

// Utility functions for tracking events
const Mixpanel = {
    track: (event, properties) => {
        mixpanel.track(event, properties);
    },
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    people: {
        set: (properties) => {
            mixpanel.people.set(properties);
        },
    },
};

export default Mixpanel