import React from 'react';
import './CelebritySearchResultItem.css';

const CelebritySearchResultItem = ({ celeb, onClick }) => {
    return (
        <div
            className="celebrity-search-result-item-container"
            onClick={() => onClick(celeb)}
        >
            <img src={celeb.image_url} className='search-result-image' onClick={() => { onClick(celeb.image_url) }} alt="" />
            <p>{celeb.full_name}</p>
        </div >
    );
};

export default CelebritySearchResultItem;