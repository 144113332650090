import React, { useEffect, useState } from "react";
import CollectionView from "./CollectionView/CollectionView";
import HeaderView from "./HeaderView/HeaderView";
import "./Landing.css";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Mixpanel from './MixpanelAnalytics';

function Landing({ baseURL, env }) {
    const navigate = useNavigate();

    // IMAGES
    const [images, setImages] = useState([]);
    const [addAnotherImage, setAddAnotherImage] = useState(null);

    const [isBlending, setIsBlending] = useState(false);
    const [errorAlertTitle, setErrorAlertTitle] = useState(null);

    // COLLECTION VIEW
    const [isSticky, setIsSticky] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight > 900) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("resize", handleResize);

        // Check the window height on initial load
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const createMixmatchClicked = async () => {
        const url = `${baseURL}/web/blend`;

        setIsBlending(true);

        const imagesToUpload = images.filter(
            (item, _) => !item.startsWith("https://")
        );
        let tempImages = images.filter((item) => !imagesToUpload.includes(item));

        if (imagesToUpload.length > 0) {
            const uploadedImageUrls = await uploadImages(imagesToUpload);
            tempImages = [...tempImages, ...uploadedImageUrls];
        }

        const body = {
            item_id: selectedItem.item_id,
            input_image_urls: tempImages,
        };

        Mixpanel.track('click_mixmatch', {
            screen: "main_page",
            item_id: selectedItem.item_id,
            item_title: selectedItem.title,
            num_of_images: tempImages.length
        });

        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.blend) {
                    env === "dev"
                        ? navigate(`/dev/result/${data.blend.blend_id}`)
                        : navigate(`/result/${data.blend.blend_id}`);
                }
                setIsBlending(false);
            })
            .catch((error) => {
                setErrorAlertTitle(JSON.stringify(error));
                setIsBlending(false);
            });
    };

    async function uploadImages(images) {
        let result = [];

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const url = `${baseURL}/web/blend/image`;

            const body = {
                image: {
                    data: image,
                },
            };
            // Perform an asynchronous operation using await
            await fetch(url, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        const imageResult = data.image.image_url;
                        result.push(imageResult);
                    } else {
                        throw "MISSING DATA";
                    }
                })
                .catch((error) => {
                    setErrorAlertTitle(JSON.stringify(error));
                    console.error("Error fetching data:", error);
                });
        }

        return result;
    }

    return (
        <div className="outside-container">
            <div
                className={`main-container ${addAnotherImage || !isSticky ? "" : "sticky-header"
                    }`}>
                <div className={`header-container`}>
                    <HeaderView
                        baseURL={baseURL}
                        images={images}
                        setImages={setImages}
                        addAnotherImage={addAnotherImage}
                        setAddAnotherImage={setAddAnotherImage}
                    />
                </div>
                <div
                    className={`scrollable-content  ${addAnotherImage || !isSticky ? "" : "sticky-header"
                        }`}>
                    <CollectionView
                        baseURL={baseURL}
                        env={env}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        createMixmatchClicked={createMixmatchClicked}
                        images={images}
                    />
                </div>
            </div>
            {selectedItem && (
                <div
                    className={`mixmatch-button ${images.length === 0 || isBlending ? "disabled" : ""
                        }`}
                    onClick={
                        images.length === 0 || isBlending ? () => { } : createMixmatchClicked
                    }>
                    <img
                        className={`${images.length === 0 || isBlending ? "disabled" : ""}`}
                        src={selectedItem?.thumbnail?.image_url}
                        alt=""></img>
                    <p
                        className={`${images.length === 0 || isBlending ? "disabled" : ""
                            }`}>
                        {isBlending ? "Loading..." : "Create Mixmatch"}
                    </p>
                </div>
            )}
            {errorAlertTitle ? (
                <Alert
                    className="error-alert"
                    variant="filled"
                    severity="error"
                    onClose={() => {
                        setErrorAlertTitle(null);
                    }}>
                    {errorAlertTitle}
                </Alert>
            ) : null}
        </div>
    );
}

export default Landing;
