import React from 'react';
import './SelectedImageItemView.css';

const SelectedImageItemView = ({ image, onDelete }) => {
  return (
    <div className="selected-image-item-container">
      <button className="selected-image-item-close-button" onClick={() => onDelete(null)}>X</button>
      <img src={image} alt="Uploaded" className="selected-image-item" />
    </div>
  );
};

export default SelectedImageItemView;