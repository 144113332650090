import React from 'react';
import './BodyFilterItem.css';

const BodyFilterItem = ({ item, isSelected, onClick }) => {
    return (
        <div
            className={`filter-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onClick(item)}
        >
            <div className={`filter-item-image-background ${isSelected ? 'selected' : ''}`}>
                <img src={item?.thumbnail?.image_url} alt={item.title} />
            </div>
            <div className={`filter-item-bottom-view ${isSelected ? 'selected' : ''}`}>
                <p>{item.title}</p>
            </div>
        </div >
    );
};

export default BodyFilterItem;