import "./ResultImageView.css"
import logo512 from "../Assets/logo512.png"

const ResultImageView = ({ isLoading, blendData, isDownloadingResult }) => {

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div className="result-image-container">
            <div className="image-container">
                {isLoading ? (
                    <div className="shimmer-item-result" />
                ) : (
                    <>
                        {blendData && <img onContextMenu={handleContextMenu} src={blendData.result_image_url} alt="Generated" className="generated-image" />}
                    </>
                )}
            </div>
            {blendData &&
                <>
                    <div className="top-label-container">
                        <span className="label">{blendData.item_data.title}</span>
                    </div>
                    {isDownloadingResult && <img alt="" src={logo512} style={{ position: "absolute", borderRadius: 7, width: 30, height: 30, bottom: 20, right: 15 }} />}
                    <div className="bottom-label-container">
                        <span className="label">Created with Mixmatch</span>
                    </div>
                </>
            }
        </div >
    )
}

export default ResultImageView