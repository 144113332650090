import React, { useEffect, useState } from 'react';
import './CollectionView.css';
import BodyFilterItem from './BodyFilterItem';

const CollectionView = ({ isBlending, baseURL, env, selectedItem, setSelectedItem, createMixmatchClicked, images }) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (baseURL) {
            setIsLoading(true)
            fetch(`${baseURL}/web/mix/category/all`)
                .then(response => response.json())
                .then(data => {
                    setCategories(data.categories);
                    if (data.categories[0] && data.categories[0].items[0]) {
                        setSelectedItem(data.categories[0].items[0])
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', JSON.stringify(error));
                    setIsLoading(false);
                });
        }
    }, [baseURL, setSelectedItem]);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const renderLoadingShimmer = () => (
        <div className="filter-section">
            <p align="left" className='horizontal-section-title'>· · · · · ·</p>
            <div className="horizontal-scroll-shimmer">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className={`shimmer-item-image-background`}>
                        <div className="shimmer-item" />
                    </div>
                ))}
            </div>
            <p align="left" className='horizontal-section-title'>· · · · · ·</p>
            <div className="horizontal-scroll-shimmer">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className={`shimmer-item-image-background`}>
                        <div className="shimmer-item" />
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="container-view">
            <div className="collection-view">
                {isLoading && renderLoadingShimmer()}
                {categories.map((category) => (
                    <div className="filter-section" key={category.category_id}>
                        <p align="left" className='horizontal-section-title'>{category.title}</p>
                        <div className="horizontal-scroll">
                            {category.items.map((item) => (
                                <BodyFilterItem
                                    key={item.item_id}
                                    item={item}
                                    isSelected={selectedItem === item}
                                    onClick={handleItemClick}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CollectionView;