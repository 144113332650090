import "./CreateBlendImageView.css"
import ImageUploader from "../ImageUploader/ImageUploader"
import SelectedImagesView from "../SelectedImages/SelectedImagesView"


const CreateBlendImageView = ({ baseURL, images, setImages, addAnotherImage, setAddAnotherImage }) => {
    const addNewImage = (newImage) => {
        setImages([...images, newImage])
        setAddAnotherImage(false)
    }

    const removeImages = (indexToRemove) => {
        setImages(images.filter((_, index) => index !== indexToRemove));
    }

    return (
        <div className="create-blend-image-view-container">
            {
                (images.length > 0)
                    ? (<SelectedImagesView images={images} onDelete={removeImages} addAnotherImage={() => setAddAnotherImage(true)} />)
                    : (null)
            }
            {
                (images.length < 3 && (addAnotherImage || images.length === 0))
                    ? (<ImageUploader baseURL={baseURL} setImage={addNewImage} closeContainer={() => setAddAnotherImage(false)} showCloseButton={images.length > 0} />)
                    : (null)
            }
        </div>
    )
}

export default CreateBlendImageView