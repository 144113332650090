
import React, { useEffect, useState } from 'react';
import './ImageUploader.css';
import OrSeparator from './OrSeparatorView';
import CelebritySearchBar from '../Search/CelebritySearchBar';
import CelebritySearchResultItem from '../Search/CelebritySearchResultItem';
import { ReactComponent as CloseButton } from '../Assets/x-mark.svg'
import Mixpanel from '../MixpanelAnalytics';

const ImageUploader = ({ baseURL, setImage, closeContainer, showCloseButton }) => {
  const [query, setQuery] = useState(null)

  useEffect(() => {
    if (query) {
      fetch(`${baseURL}/web/celebs?text=${query}`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data.celebs);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      setSearchResults([]);
    };
  }, [baseURL, query]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        Mixpanel.track('screen_image_upload', {
          screen: "main_page",
        });
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        Mixpanel.track('screen_image_upload', {
          screen: "main_page",
        });
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    setQuery(query)
  };

  const handleCelebOnClick = (image_url) => {
    Mixpanel.track('click_celeb', {
      screen: "main_page",
      image_url: image_url
    });
    setImage(image_url)
  };

  return (
    <div className='image-uploader-container'>
      <h3 style={{ marginBottom: 0 }}>Add an Image</h3>
      {(showCloseButton) ? (<CloseButton className="image-uploader-close-button" onClick={closeContainer} />) : (null)}
      <div className="image-uploader">
        <div className="upload-area" onDrop={handleDrop} onDragOver={handleDragOver}>
          <div className="upload-placeholder">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <label htmlFor="fileInput" className="upload-label">
              Drag an image here or <span className="upload-link">upload a file</span>
            </label>
          </div>
        </div>
        <OrSeparator />
        <CelebritySearchBar onSearch={handleSearch} />
        {query && (searchResults ?
          (<ul className="search-results-horizontal-scroll">
            {searchResults.map((result, index) => (
              <CelebritySearchResultItem key={index} celeb={result} onClick={() => handleCelebOnClick(result.image_url)} />
            ))}
          </ul>) :
          (<p>No Results</p>)
        )}
      </div>
    </div>
  );
};

export default ImageUploader
