import "./ResultFooterView.css"
import arrowDown from "../Assets/arrow-down-tray.png"

const ResultFooterView = ({ downloadClicked, downloadWithOriginals, setDownloadWithOriginals }) => {

    const handleCheckboxChange = (event) => {
        setDownloadWithOriginals(event.target.checked);
    };

    return (
        <div className="download-container">
            <div className="switch-container">
                <span className="label-text">Download with Originals</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={downloadWithOriginals}
                        onChange={handleCheckboxChange}
                    />
                    <span className="slider"></span>
                </label>
            </div>
            <div className="download-button" onClick={downloadClicked}>
                <img src={arrowDown} width={20} height={20} ></img>
                <p>Download Image</p>
            </div>
        </div>
    )
}

export default ResultFooterView