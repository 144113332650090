
import './OrSeparatorView.css';

const OrSeparator = () => {
    return (
    <div className='separator-container'>
        <div className='rectangle'></div>
        <div className="title">OR</div>
        <div className='rectangle'></div>
    </div>
    
    );
};
export default OrSeparator